<template>
  <div id="app">
    <div :class="preview ? 'outputcontainer' : 'container'">
      <div class="content" v-if="!preview">
        <div align="center">
          <img
            src="@/assets/company-logo.png"
            alt="logo"
            width="120"
            style="display: inline-block"
          />
          <h3 style="margin: 16px 0">Generate your Signature</h3>
          <hr />
        </div>
        <p style="margin: 20px 0">
          Fill in your details to generate your signature
        </p>
        <form @submit.prevent="generateSignature">
          <div class="input-box">
            <input
              type="text"
              v-model="signature.name"
              placeholder="Your Name"
              name="name"
              required
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              v-model="signature.title"
              placeholder="Your Title"
              name="Title"
              required
            />
          </div>
          <div class="input-box">
            <input
              type="email"
              v-model="signature.email"
              placeholder="Email Address"
              name="email"
              required
            />
          </div>
          <div class="input-box">
            <input
              type="tel"
              v-model="signature.phone"
              placeholder="Phone Number (10 Digits)"
              name="phone"
              pattern="[0-9]{10}"
              required
            />
          </div>
          <div class="input-box" align="center">
            <button type="submit">Generate Signature</button>
          </div>
        </form>
      </div>
      <div v-if="preview">
        <signature-output :signature="signature" />
      </div>
    </div>
  </div>
</template>

<script>
import SignatureOutput from "./SignatureOutput.vue";

export default {
  name: "SignatureForm",
  components: {
    SignatureOutput,
  },
  data() {
    return {
      preview: false,
      signature: {
        name: "",
        title: "",
        email: "",
        phone: "",
        company: "Maverick Power",
        company_linkedin: "https://www.linkedin.com/company/maverick-power",
        company_website: "www.maverickpwr.com",
      },
    };
  },
  methods: {
    generateSignature() {
      if (this.isValidForm()) {
        this.signature.phone = this.formatPhone(this.signature.phone);
        this.preview = true;
      } else {
        this.preview = false;
        alert("Please fill in all required fields correctly.");
      }
    },
    isValidForm() {
      return (
        this.signature.name &&
        this.signature.title &&
        this.signature.email &&
        this.signature.phone.match(/^[0-9]{10}$/)
      );
    },
    formatPhone(phone) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  color: #565656;
}

.container {
  max-width: 750px;
  min-width: 750px;
  padding: 28px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 15px 20px rgb(0 0 0 / 10%);
  margin: 50px auto;
}
.outputcontainer {
  max-width: 750px;
  padding: 40px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  margin: 20px auto;
}

h2 {
  font-size: 26px;
  font-weight: bold;
  text-align: left;
  color: #565656;
  padding-bottom: 8px;
  border-bottom: 1px solid silver;
}

.content {
  justify-content: space-between;
  padding: 20px 0;
}

.input-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 15px;
}

.input-box:nth-child(2n) {
  justify-content: end;
}

.input-box label,
.gender-title {
  width: 95%;
  color: #fff;
  font-weight: bold;
  margin: 5px 0;
}

.gender-title {
  font-size: 16px;
}

.input-box input {
  height: 40px;
  width: 100%;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
}

button {
  width: 100%;
  background: #003d8c;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin: 15px auto;
  padding: 14px;
}

.input-box input:is(:focus, :valid) {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.flex {
  display: flex;
  margin-bottom: 6px;
}

.flex h6 {
  font-size: 16px;
  font-weight: 600;
  min-width: 132px;
}

@media (max-width: 600px) {
  .container {
    min-width: 280px;
  }
  .content {
    max-height: 100%;
    overflow: auto;
  }
  .input-box {
    width: 100%;
  }
  .input-box:nth-child(2n) {
    justify-content: space-between;
  }
  .outputcontainer {
    padding: 20px;
  }
}
</style>
