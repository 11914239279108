<template>
  <div ref="signaturePreview" style="padding: 0; margin: 0">
    <table
      cellpadding="0"
      cellspacing="0"
      border="0"
      style="
        vertical-align: -webkit-baseline-middle;
        font-size: medium;
        font-family: Calibri, sans-serif;
      "
    >
      <tbody>
        <tr>
          <td nowrap>
            <table
              cellpadding="0"
              cellspacing="0"
              border="0"
              style="
                vertical-align: -webkit-baseline-middle;
                font-size: medium;
                font-family: Calibri, sans-serif;
              "
            >
              <tbody>
                <tr>
                  <!-- Logo and Tagline Section -->
                  <td nowrap style="vertical-align: top">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                      style="
                        vertical-align: -webkit-baseline-middle;
                        font-size: medium;
                        font-family: Calibri, sans-serif;
                      "
                    >
                      <tbody>
                        <tr>
                          <!-- Company Logo -->
                          <td nowrap style="text-align: center">
                            <a
                              :href="'https://' + signature.company_website"
                              target="_blank"
                            >
                              <img
                                :src="companyLogoDataUrl"
                                alt="Logo"
                                role="presentation"
                                width="118"
                                style="display: block; max-width: 118px"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <!-- Tagline Image -->
                          <td
                            nowrap
                            style="text-align: center; padding-top: 10px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              style="
                                display: inline-block;
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr style="text-align: center">
                                  <td nowrap>
                                    <img
                                      :src="taglineImageDataUrl"
                                      alt="Tagline"
                                      width="112"
                                      style="width: 112px; margin-top: 8px"
                                    />
                                  </td>
                                  <td nowrap width="5">
                                    <div></div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <!-- Spacer -->
                  <td nowrap width="20">
                    <div></div>
                  </td>
                  <!-- User Information Section -->
                  <td nowrap style="padding: 0px; vertical-align: top">
                    <!-- Name -->
                    <h2
                      style="
                        margin: 0px;
                        font-size: 16px;
                        color: #003d8c;
                        font-weight: 600;
                      "
                    >
                      <span>{{ signature.name || "Your Name" }}</span>
                    </h2>
                    <!-- Title -->
                    <p
                      style="
                        margin: 0px;
                        color: #003d8c;
                        font-size: 14px;
                        line-height: 22px;
                      "
                    >
                      <span>{{ signature.title || "Your Title" }}</span>
                    </p>
                    <!-- Company -->
                    <p
                      style="
                        margin: 0px;
                        font-weight: 500;
                        color: #003d8c;
                        font-size: 14px;
                        line-height: 22px;
                      "
                    >
                      <span>{{
                        signature.company || "Your Company Name"
                      }}</span>
                    </p>

                    <!-- Spacing -->
                    <table>
                      <tbody>
                        <tr
                          height="13"
                          style="vertical-align: middle; height: 13px"
                        >
                          <td
                            nowrap
                            width="130"
                            height="13"
                            style="height: 13px; vertical-align: top"
                          >
                            <div
                              style="
                                vertical-align: middle;
                                height: 10px;
                                font-size: 10px;
                              "
                            >
                              <p style="margin: 0">
                                <span style="font-size: 6px">&nbsp;</span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- Contact Information -->
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                      style="
                        vertical-align: -webkit-baseline-middle;
                        font-size: medium;
                        font-family: Calibri, sans-serif;
                      "
                    >
                      <tbody>
                        <!-- Phone -->
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td
                                    nowrap
                                    style="
                                      vertical-align: bottom;
                                      text-align: center;
                                    "
                                  >
                                    <img
                                      :src="phoneIconDataUrl"
                                      alt="Phone"
                                      width="14"
                                      height="14"
                                      style="
                                        display: block;
                                        width: 14px;
                                        height: 14px;
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px; color: #757575"
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <p style="margin: 0">
                                      <span
                                        style="
                                          font-size: 10px;
                                          text-decoration: none;
                                        "
                                      >
                                        <a
                                          :href="'tel:' + signature.phone"
                                          style="
                                            text-decoration: none;
                                            border-bottom: 0px;
                                            color: #757575;
                                            font-size: 10px;
                                          "
                                        >
                                          <span style="text-decoration: none">
                                            {{
                                              signature.phone || "123-456-7890"
                                            }}
                                          </span>
                                        </a>
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <!-- Email -->
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td
                                    nowrap
                                    style="
                                      vertical-align: bottom;
                                      text-align: center;
                                    "
                                  >
                                    <img
                                      :src="emailIconDataUrl"
                                      alt="Email"
                                      width="14"
                                      height="12"
                                      style="
                                        display: block;
                                        width: 14px;
                                        height: 12px;
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px"
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <p style="margin: 0">
                                      <span
                                        style="
                                          font-size: 10px;
                                          text-decoration: none;
                                        "
                                      >
                                        <a
                                          :href="'mailto:' + signature.email"
                                          style="
                                            text-decoration: none;
                                            border-bottom: 0px;
                                            color: #757575;
                                            font-size: 10px;
                                          "
                                        >
                                          <span style="text-decoration: none">
                                            {{
                                              signature.email ||
                                              "youremail@example.com"
                                            }}
                                          </span>
                                        </a>
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <!-- Website -->
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td
                                    nowrap
                                    style="
                                      vertical-align: bottom;
                                      text-align: center;
                                    "
                                  >
                                    <img
                                      :src="websiteIconDataUrl"
                                      alt="Website"
                                      width="15"
                                      height="15"
                                      style="
                                        display: block;
                                        width: 15px;
                                        height: 15px;
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px"
                          >
                            <p style="margin: 0">
                              <span
                                style="
                                  font-size: 10px;
                                  text-decoration: none;
                                  color: #757575;
                                "
                              >
                                <a
                                  :href="'https://' + signature.company_website"
                                  target="_blank"
                                  style="
                                    text-decoration: none;
                                    border-bottom: 0px;
                                    color: #757575;
                                    font-size: 10px;
                                  "
                                >
                                  <span style="text-decoration: none">
                                    {{
                                      signature.company_website ||
                                      "www.yourcompany.com"
                                    }}
                                  </span>
                                </a>
                              </span>
                            </p>
                          </td>
                        </tr>
                        <!-- LinkedIn -->
                        <tr
                          height="25"
                          style="vertical-align: middle; height: 25px"
                        >
                          <td
                            nowrap
                            width="26"
                            height="25"
                            style="vertical-align: middle; height: 25px"
                          >
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              border="0"
                              style="
                                vertical-align: -webkit-baseline-middle;
                                font-size: medium;
                                font-family: Calibri, sans-serif;
                              "
                            >
                              <tbody>
                                <tr>
                                  <td
                                    nowrap
                                    style="
                                      vertical-align: bottom;
                                      text-align: center;
                                    "
                                  >
                                    <img
                                      :src="linkedinIconDataUrl"
                                      alt="LinkedIn"
                                      width="17"
                                      height="17"
                                      style="
                                        display: block;
                                        width: 17px;
                                        height: 17px;
                                      "
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            nowrap
                            height="25"
                            style="padding: 0px; height: 25px"
                          >
                            <p style="margin: 0">
                              <span
                                style="
                                  font-size: 10px;
                                  text-decoration: none;
                                  color: #757575;
                                "
                              >
                                <a
                                  :href="signature.company_linkedin"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style="
                                    text-decoration: none;
                                    border-bottom: 0px;
                                    color: #757575;
                                    font-size: 10px;
                                  "
                                >
                                  {{ signature.linkedin_handle || "LinkedIn" }}
                                </a>
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <!-- Divider -->
        <tr>
          <td style="border-bottom: 2px solid #dadada">&nbsp;</td>
        </tr>
        <!-- Padding between divider and footer image -->
        <tr>
          <td style="height: 21px">&nbsp;</td>
        </tr>

        <!-- Footer Image -->
        <tr>
          <td nowrap>
            <img :src="footerImageDataUrl" alt="Footer Image" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="height: 50px"></div>
  <button @click="copyToClipboard" class="btn-clipboard">Copy Signature</button>
  <p v-if="isMobile" style="font-size: 12px; color: #757575">
    Please manually select and copy the signature if you're using a mobile
    device.
  </p>
</template>

<script>
import companyLogo from "@/assets/company-logo.png";
import taglineImage from "@/assets/tagline.png";
import phoneIcon from "@/assets/phone-icon.png";
import emailIcon from "@/assets/email-icon.png";
import websiteIcon from "@/assets/website-icon.png";
import linkedinIcon from "@/assets/linkedin-icon.png";
import footerImage from "@/assets/right-banner-image.png";

export default {
  name: "SignatureOutput",
  props: {
    signature: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      companyLogoDataUrl: "",
      taglineImageDataUrl: "",
      phoneIconDataUrl: "",
      emailIconDataUrl: "",
      websiteIconDataUrl: "",
      linkedinIconDataUrl: "",
      footerImageDataUrl: "",
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent), // Detect mobile devices
    };
  },
  mounted() {
    // Convert images to Base64 data URLs
    this.convertImageToDataUrl(companyLogo, (dataUrl) => {
      this.companyLogoDataUrl = dataUrl;
    });
    this.convertImageToDataUrl(taglineImage, (dataUrl) => {
      this.taglineImageDataUrl = dataUrl;
    });
    this.convertImageToDataUrl(phoneIcon, (dataUrl) => {
      this.phoneIconDataUrl = dataUrl;
    });
    this.convertImageToDataUrl(emailIcon, (dataUrl) => {
      this.emailIconDataUrl = dataUrl;
    });
    this.convertImageToDataUrl(websiteIcon, (dataUrl) => {
      this.websiteIconDataUrl = dataUrl;
    });
    this.convertImageToDataUrl(linkedinIcon, (dataUrl) => {
      this.linkedinIconDataUrl = dataUrl;
    });
    this.convertImageToDataUrl(footerImage, (dataUrl) => {
      this.footerImageDataUrl = dataUrl;
    });
  },
  methods: {
    convertImageToDataUrl(src, callback) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL();
        callback(dataURL);
      };
      img.src = src;
    },
    copyToClipboard() {
      const signatureElement = this.$refs.signaturePreview;

      const tempElement = document.createElement("div");
      tempElement.appendChild(signatureElement.cloneNode(true));

      const htmlContent = tempElement.innerHTML;
      const plainTextContent = signatureElement.textContent; // Fallback for mobile devices

      if (navigator.clipboard && navigator.clipboard.write) {
        // Use Clipboard API
        const clipboardItems = [
          new ClipboardItem({
            "text/html": new Blob([htmlContent], { type: "text/html" }),
            "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
          }),
        ];

        navigator.clipboard
          .write(clipboardItems)
          .then(() => {
            alert("Signature copied to clipboard!");
          })
          .catch((err) => {
            console.error("Clipboard API failed:", err);
            this.selectSignatureForCopy();
          });
      } else {
        this.selectSignatureForCopy(); // Fallback for unsupported browsers or mobile
      }
    },

    selectSignatureForCopy() {
      const signatureElement = this.$refs.signaturePreview;

      // Use range selection as fallback
      const range = document.createRange();
      range.selectNode(signatureElement);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          alert("Signature copied to clipboard!");
        } else {
          alert("Please manually copy the selected signature content.");
        }
      } catch (err) {
        console.error("Copy command failed:", err);
        alert("Please manually copy the selected signature content.");
      }

      selection.removeAllRanges(); // Clear the selection
    },
  },
};
</script>

<style>
a:-webkit-any-link {
  text-decoration: none;
  border-bottom: 0px;
}
button {
  width: 100%;
  background: #003d8c;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 40px auto 0;
  padding: 14px;
}

button:hover {
  background-color: #002d6c;
}
.btn-clipboard {
  width: 100%;
  background-color: #757575;
  margin: 0;
  padding: 10px 5px;
}

/* Mobile Specific Styles */
@media (max-width: 768px) {
  table {
    width: 100%;
  }

  h2 {
    font-size: 14px;
  }

  p {
    font-size: 12px;
  }

  .btn-clipboard {
    font-size: 14px;
    padding: 12px;
  }
}
</style>
