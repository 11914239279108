<template>
  <SignatureForm />
</template>

<script>
import SignatureForm from "./components/SignatureForm.vue";

export default {
  name: "App",
  components: {
    SignatureForm,
  },
};
</script>
